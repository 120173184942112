import { combineReducers } from 'redux';
import login from './login';
import drawer from './drawer';
import circularProgress from './circularProgress';
import snackbar from './snackbar';
import sendEmail from './sendEmail';

export default combineReducers({
  login: login,
  drawer: drawer,
  circularProgress: circularProgress,
  snackbar: snackbar,
  sendEmail: sendEmail,
});
