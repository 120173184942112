const development = {
  url: 'http://127.0.0.1:80/api',
};

const production = {
  url: 'https://pegasus-api.damar.finance/api',
  storage: 'https://pegasus-api.damar.finance/storage',
};

export default process.env.NODE_ENV === 'production' ? production : development;
