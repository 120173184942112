import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },

  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  contentHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  accountCircleIcon: {
    cursor: 'pointer',
  },

  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingLeft: 15,
    paddingRight: 15,
  },
}));
