import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LOGOUT_SUCCESS,
  SET_LOGIN_ACCESS,
  LOGOUT_ERROR,
} from '../../../redux/types';

const initialState = {
  error: '',
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);

      return {
        ...state,
        isLogin: true,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        isLogin: true,
      };

    case GET_USER_ERROR:
    case LOGOUT_ERROR:
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLogin: false,
      };

    case LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      return {
        ...state,
        isLogin: false,
      };

    case SET_LOGIN_ACCESS:
      return {
        ...state,
        isLogin: action.payload,
      };

    default:
      return state;
  }
}
