import { ENQUEUE_SNACKBAR } from "../../types";

const initialState = {
  notifications: []
};

export default function snackbar(state = initialState, action) {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };

    default:
      return state;
  }
}
