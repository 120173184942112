import {
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_SUCCESS,
  OPEN_MODAL,
  EMAIL_COUNTER,
  ACTIVITY_INDICATOR,
} from '../../types';

const initialState = {
  error: '',
  openModal: false,
  emailCounter: 0,
  activityIndicator: '',
};

export default function sentEmail(state = initialState, action) {
  switch (action.type) {
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
      };

    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case OPEN_MODAL:
      return {
        ...state,
        openModal: action.payload,
      };

    case EMAIL_COUNTER:
      return {
        ...state,
        emailCounter: action.payload,
      };

    case ACTIVITY_INDICATOR:
      return {
        ...state,
        activityIndicator: action.payload,
      };

    default:
      return state;
  }
}
