import { sendEmailConfig } from '../../../axios/routes';
import {
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  OPEN_MODAL,
  EMAIL_COUNTER,
  ACTIVITY_INDICATOR,
} from '../../types';
import axiosService from '../../../axios';
import history from '../../../router/history';
import { v4 as uuidv4 } from 'uuid';

// Returns a Promise that resolves after "ms" Milliseconds
function timer(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

export function sendEmailAction(sendEmailData, image) {
  return async (dispatch) => {
    try {
      await dispatch(openModalAction(true));

      let formData = new FormData();
      image.length > 0 && formData.append('file', image[0]);

      formData.append('title', sendEmailData.title);
      formData.append('titleColorCode', sendEmailData.titleColorCode);
      formData.append('content', JSON.stringify(sendEmailData.content));
      formData.append('fileName', uuidv4());

      await dispatch(activityIndicatorAction('circular'));

      for (let i = 0; i < sendEmailData.emails.length; i = i + 1) {
        let emailData = sendEmailData.emails.slice(i, i + 1);

        formData.append('email', JSON.stringify(emailData));

        await axiosService(sendEmailConfig, formData);

        await dispatch(emailCounterAction(i + 1));

        formData.delete('email');

        await timer(2500);
      }

      await dispatch(activityIndicatorAction('success'));

      await dispatch(sendEmailSuccess(true));

      return history.push('/home');
    } catch (error) {
      await dispatch(activityIndicatorAction('error'));

      return dispatch(sendEmailFailure(false));
    }
  };
}

export const sendEmailSuccess = (data) => {
  return {
    type: SEND_EMAIL_SUCCESS,
    payload: data,
  };
};

export const sendEmailFailure = (data) => {
  return {
    type: SEND_EMAIL_FAILURE,
    payload: data,
  };
};

export const openModalAction = (data) => {
  return {
    type: OPEN_MODAL,
    payload: data,
  };
};

export const emailCounterAction = (data) => {
  return {
    type: EMAIL_COUNTER,
    payload: data,
  };
};

export const activityIndicatorAction = (data) => {
  return {
    type: ACTIVITY_INDICATOR,
    payload: data,
  };
};
