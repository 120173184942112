// Login user
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// Login access
export const SET_LOGIN_ACCESS = "SET_LOGIN_ACCESS";

// Get current user
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

// Get current user
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

// Drawer and App bar type
export const SET_MOBILE_OPEN = "SET_MOBILE_OPEN";

// Circular progress
export const CIRCULAR_PROGRESS = "CIRCULAR_PROGRESS";

// Snackbar
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";

export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

// Open Modal
export const OPEN_MODAL = "OPEN_MODAL";
export const EMAIL_COUNTER = "EMAIL_COUNTER";
export const ACTIVITY_INDICATOR = "ACTIVITY_INDICATOR";


