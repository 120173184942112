import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

export const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },

  submit: {
    margin: theme.spacing(3, 0, 2)
  },

  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: grey[800]
  },

  content: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  },

  logo: {
    paddingBottom: "20px",
    width: "300px"
  },

  paper: {
    backgroundColor: "rgba(255,255,255,0.7)"
  },

  paperContent: {
    flexDirection: "column",
    alignItems: "center",
    display: "flex"
  }
}));
