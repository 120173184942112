import axios from "axios";
import history from "../router/history";
import { loginAccessAction } from "../redux/actions/login";
import rootReducer from "../store";
import environment from "./environment";

const { dispatch } = rootReducer; // direct access to redux store.

const API_URL = environment.url;

//*------- Axios interseptors --------*//
axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem("token");

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (401 === error.response.status) {
      dispatch(loginAccessAction(false));
      localStorage.removeItem("token");
      history.push("/");

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

//*--------------------------------------*//

export default (config, data) => {
  switch (config.method) {
    case "POST":
      return axios.post(API_URL + config.url, data);
    case "PUT":
      return axios.put(API_URL + config.url, data);
    case "GET":
      return axios.get(API_URL + config.url);
    default:
      return;
  }
};
