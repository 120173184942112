import { CIRCULAR_PROGRESS } from "../../../redux/types";

const initialState = {
  circularProgress: false
};

export default function circularProgress(state = initialState, action) {
  switch (action.type) {
    case CIRCULAR_PROGRESS:
      return { ...state, circularProgress: action.payload };

    default:
      return state;
  }
}
