import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },

  appBar: {
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   //marginLeft: drawerWidth,
    // },
    zIndex: 900,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    paddingLeft: 15,
    paddingRight: 15,
  },

  contentHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  accountCircleIcon: {
    cursor: 'pointer',
  },
}));
