import React from 'react';

/* Material UI */
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Paper, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

/* Custom import */
import { useStyles } from './styles';
import images from './../../assets/images';
import { loginAction } from '../../utils/redux/actions/login';
import Notifier from '../../components/notifier';

function Login({ loginAction, loginError }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Notifier />

      <Grid item xs={10} className={classes.content}>
        <img
          alt='description of image'
          aria-hidden
          src={images.transparentLogo}
          className={classes.logo}
        />

        <Paper elevation={15} variant='elevation' className={classes.paper}>
          <Grid container justify='center'>
            <Grid item xs={10} md={10} className={classes.paperContent}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography color={'secondary'}>
                {loginError && loginError}
              </Typography>
              <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={(values) => {
                  loginAction(values);
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required('Required'),
                  password: Yup.string().required('Required'),
                })}
              >
                {({ values, errors, touched, handleChange }) => (
                  <Form>
                    <TextField
                      variant='filled'
                      margin='normal'
                      fullWidth
                      id='email'
                      label='Email Adresa'
                      name='email'
                      autoComplete='email'
                      error={touched.email && Boolean(errors.email)}
                      value={values.email}
                      onChange={handleChange}
                    />
                    <TextField
                      variant='filled'
                      margin='normal'
                      fullWidth
                      name='password'
                      label='Lozinka'
                      type='password'
                      id='password'
                      autoComplete='current-password'
                      error={touched.password && Boolean(errors.password)}
                      value={values.password}
                      onChange={handleChange}
                    />
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      Uloguj se
                    </Button>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.login,
    loginError: state.login.error,
  };
};

const mapDispatchToProps = { loginAction };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
