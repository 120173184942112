import React from 'react';
import { Route } from 'react-router-dom';

/* Material UI */
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBarCustom from '../../components/material-ui/AppBar';

/* Custom imports */
import { useStyles } from './styles';

function HomeLayout(props) {
  const classes = useStyles(); // style classes

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarCustom />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route path={props.path}>{props.component}</Route>
      </main>
    </div>
  );
}

export default HomeLayout;
