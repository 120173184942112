import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  container: {
    flex: 1,

    display: 'flex',
    backgroundColor: grey[800],
  },

  paper: {
    backgroundColor: 'rgba(255,255,255,0.85)',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },

  paperContent: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },

  imageContent: {
    height: 80,
    width: 80,
    position: 'relative',
    marginTop: 10,
  },

  deleteImageContent: {
    position: 'absolute',
    top: '4px',
    right: '2px',
    cursor: 'pointer',
  },

  deleteImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    border: 'solid',
    borderColor: 'silver',
    borderWidth: '1px',
    borderRadius: '5px',
    cursor: 'pointer',
  },

  deleteImageFile: {
    marginLeft: 10,
    cursor: 'pointer',
  },

  dragDropImageContent: {
    display: 'flex',
    overflowY: 'auto',
    width: '100%',
    maxHeight: 300,
  },

  buttonContent: {
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
      alignItems: 'center',
      marginBottom: 8,
    },

    marginBottom: 8,
  },

  button: {
    width: 250,
    height: 50,
  },
}));
