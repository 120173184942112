import {
  loginConfig,
  getUserConfig,
  logoutConfig,
} from "../../../axios/routes";
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SET_LOGIN_ACCESS,
} from "../../types";
import axiosService from "../../../axios";
import history from "../../../router/history";

export function loginAction(loginData) {
  return async (dispatch) => {
    try {
      let data = await axiosService(loginConfig, loginData);
      dispatch(loginSuccess(data));
      return history.push("/home");
    } catch (error) {
      return dispatch(loginError(error));
    }
  };
}

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data.data,
  };
};

export const loginError = (data) => {
  return {
    type: LOGIN_ERROR,
    payload: data.response.data,
  };
};

export function getUserAction() {
  return async (dispatch) => {
    try {
      let data = await axiosService(getUserConfig);
      dispatch(getUserSuccess(data));
      history.push("/home");
    } catch (error) {
      return dispatch(getUserError(error));
    }
  };
}

export const getUserSuccess = (data) => {
  return {
    type: GET_USER_SUCCESS,
    payload: data.data,
  };
};

export const getUserError = (data) => {
  return {
    type: GET_USER_ERROR,
    payload: data.response.data,
  };
};

export function logoutAction() {
  return async (dispatch) => {
    try {
      let data = await axiosService(logoutConfig);
      dispatch(logoutSuccess(data));
      history.push("/");
    } catch (error) {
      return dispatch(logoutError(error));
    }
  };
}

export const logoutSuccess = (data) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: data,
  };
};

export const logoutError = (data) => {
  return {
    type: LOGOUT_ERROR,
    payload: data.response.data,
  };
};

export const loginAccessAction = (data) => {
  return {
    type: SET_LOGIN_ACCESS,
    payload: data,
  };
};
