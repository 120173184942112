import { SET_MOBILE_OPEN } from "../../../redux/types";

const initialState = {
  setMobileOpen: false
};

export default function drawer(state = initialState, action) {
  switch (action.type) {
    case SET_MOBILE_OPEN:
      return { ...state, setMobileOpen: action.payload };

    default:
      return state;
  }
}
