import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from './utils/router/history';
import { SnackbarProvider } from 'notistack';
import { connect } from 'react-redux';

/* Material UI */
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import blueGrey from '@material-ui/core/colors/blueGrey';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Login from './../src/pages/Login';
import HomeLayout from './layout/Home';
import Home from './pages/Home';

import { getUserAction } from "./utils/redux/actions/login";

/* Route */
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000,
    color: '#fff',
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: { main: '#debf64' },
    secondary: { main: red[500] },
  },

  overrides: {
    MuiDrawer: {
      paper: {
        zIndex: 900,
        background: blueGrey[800],
        '& *': { color: '#fff' },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

function App({ circularProgress, isLogin, getUserAction }) {
  const classes = useStyles(); // style classes

  useEffect(() => {
    getUserAction();
  }, [getUserAction]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Router history={history}>
          {isLogin !== undefined && (
            <Switch>
              {/* <HomeLayout path='/home' component={<Home />} />

              <Route path='/'>
                <Login />
              </Route> */}
              {isLogin && <HomeLayout path='/home' component={<Home />} />}
              {isLogin === false && (
                <Route path='/'>
                  <Login />
                </Route>
              )}
            </Switch>
          )}
        </Router>
      </SnackbarProvider>
      <Backdrop className={classes.backdrop} open={circularProgress}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ circularProgress, login }) => {
  return {
    circularProgress: circularProgress.circularProgress,
    isLogin: login.isLogin,
  };
};

const mapDispatchToProps = {
  getUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
