import React, { useRef, useEffect } from 'react';

/* Material UI */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import ImageIcon from '@material-ui/icons/Image';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';

/* Custom import */
import { useStyles } from './styles';
import Notifier from '../../components/notifier';
import Delete from '@material-ui/icons/Delete';
import CSVReader from 'react-csv-reader';
import './styles.css'; // scroll style

import {
  sendEmailAction,
  openModalAction,
} from '../../utils/redux/actions/sentEmail';

import SendIcon from '@material-ui/icons/Send';
import ModalDialog from '../../components/material-ui/ModalDialog';

function Home({
  sendEmailAction,
  openModalAction,
  openModal,
  activityIndicator,
  emailCounter,
}) {
  const classes = useStyles();
  const formikRef = useRef({});
  const [image, setImage] = React.useState([]); // image
  const [csvFile, setCsvFile] = React.useState(); // csv file
  const [lightbox, setLightbox] = React.useState(false); // Files
  const [hover, setHover] = React.useState({ hover: false, index: 0 });

  const onChangeHandlerImage = (event) => {
    const file = event.target.files[0];

    setImage([
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        fileType: 'image',
      }),
    ]);
  };

  const onChangeHandlerCsvFile = (event) => {
    let filterEmailRow = event[0][0]
      .map((mail, i) => {
        console.log(mail);
        return mail === 'Email' || mail === 'email' ? i : undefined;
      })
      .filter((data) => data !== undefined)[0];

    let emailTotal = event[0]
      .map((mail) => {
        return mail[filterEmailRow] !== '' &&
          mail[filterEmailRow] !== 'Email' &&
          mail[filterEmailRow] !== 'email'
          ? mail[filterEmailRow]
          : null;
      })
      .filter((email) => email);

    setCsvFile({ emails: emailTotal });
  };

  const sliceFunction = (source, setSource, index) => {
    const newFiles = [...source];

    newFiles.splice(index, 1);

    setSource(newFiles);
  };

  useEffect(() => {
    formikRef && formikRef.current && formikRef.current.validateForm();
  }, []);

  return (
    <div className={classes.container}>
      <Notifier />
      <Formik
        innerRef={formikRef}
        initialValues={{ title: '', titleColorCode: '', content: '' }}
        onSubmit={(values) => {
          let content = values.content.split(/\r?\n/);

          sendEmailAction(
            {
              ...values,
              content: content,
              emails: csvFile.emails,
            },
            image
          );
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Required'),
          titleColorCode: Yup.string().required('Required'),
          content: Yup.string().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, isValid }) => (
          <Form style={{ flex: 1, display: 'flex' }}>
            <Grid container>
              <Grid item md={6} xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <Typography style={{ textAlign: 'center' }} variant='h5'>
                    Naslov
                  </Typography>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    multiline
                    fullWidth
                    rows={4}
                    label='Unesite naslov e-mail - a'
                    name='title'
                    autoComplete='title'
                    error={touched.title && Boolean(errors.title)}
                    value={values.title}
                    onChange={handleChange}
                  />

                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Unesite kod boje naslova - primjer (#FFFFFF)'
                    name='titleColorCode'
                    autoComplete='titleColorCode'
                    error={
                      touched.titleColorCode && Boolean(errors.titleColorCode)
                    }
                    value={values.titleColorCode}
                    onChange={handleChange}
                  />
                </Paper>

                <Paper className={classes.paper}>
                  <Typography
                    style={{ textAlign: 'center', marginBottom: 16 }}
                    variant='h5'
                  >
                    Slika
                  </Typography>

                  <Grid
                    container
                    direction='row'
                    style={{ alignItems: 'center' }}
                  >
                    <Grid item>
                      <Button
                        variant='outlined'
                        color='primary'
                        startIcon={<ImageIcon />}
                        component='label'
                      >
                        ODPREMI SLIKU
                        <input
                          accept='image/png, image/jpeg, image/jpg'
                          type='file'
                          style={{ display: 'none' }}
                          onChange={onChangeHandlerImage}
                        />
                      </Button>
                    </Grid>

                    <Grid container className={classes.dragDropImageContent}>
                      {image?.map((file, i) => (
                        <Grid
                          item
                          key={i}
                          md={3}
                          xs={3}
                          className={classes.imageContent}
                          onMouseEnter={() =>
                            setHover({ hover: true, index: i })
                          }
                          onMouseLeave={() =>
                            setHover({ hover: false, index: i })
                          }
                        >
                          {hover.hover && hover.index === i && (
                            <Delete
                              color='secondary'
                              className={classes.deleteImageContent}
                              onClick={() => sliceFunction(image, setImage, i)}
                            />
                          )}
                          <img
                            aria-hidden
                            alt='description of image'
                            src={file.preview}
                            className={classes.deleteImage}
                            onClick={() => {
                              setLightbox(true);
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Paper>

                <Paper className={classes.paper}>
                  <Typography
                    style={{ textAlign: 'center', marginBottom: 16 }}
                    variant='h5'
                  >
                    CSV Dokument
                  </Typography>

                  <Grid
                    container
                    direction='row'
                    style={{ alignItems: 'center' }}
                  >
                    <Grid item>
                      <Button
                        variant='outlined'
                        color='primary'
                        startIcon={<ImageIcon />}
                        component='label'
                      >
                        ODPREMI DOKUMENT
                        <CSVReader
                          onFileLoaded={(data, fileInfo) =>
                            onChangeHandlerCsvFile([data, fileInfo])
                          }
                          cssInputClass='csv-reader-input'
                        />
                      </Button>
                    </Grid>

                    <Grid container className={classes.dragDropImageContent}>
                      {csvFile && (
                        <Grid
                          item
                          style={{
                            justifyContent: 'center',
                            flexDirection: 'row',
                            display: 'flex',
                          }}
                        >
                          <Typography style={{ marginTop: 10 }}>
                            Ukupno pronađeno {csvFile.emails.length} e-mail
                            adresa
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item md={6} xs={12}>
                <Paper className={classes.paper}>
                  <Typography style={{ textAlign: 'center' }} variant='h5'>
                    Sadržaj
                  </Typography>

                  <TextField
                    variant='outlined'
                    margin='normal'
                    multiline
                    fullWidth
                    rows={25}
                    label='Unesite sadržaj e-mail-a'
                    name='content'
                    autoComplete='content'
                    error={touched.content && Boolean(errors.content)}
                    value={values.content}
                    onChange={handleChange}
                  />
                </Paper>
              </Grid>
              <Grid
                container
                alignItems='flex-end'
                justify='center'
                className={classes.buttonContent}
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.button}
                  startIcon={<SendIcon />}
                  type='submit'
                  disabled={csvFile ? false : true}
                >
                  Isporuči
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {lightbox && (
        <Lightbox
          mainSrc={image[0].preview}
          onCloseRequest={() => setLightbox(false)}
        />
      )}

      <ModalDialog
        open={openModal}
        titleModal={'Slanje sadržaja'}
        submitText={'Izlaz'}
        handleSubmit={() => openModalAction(false)}
        activity={activityIndicator}
      >
        <div
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {activityIndicator === 'circular' ? (
            <CircularProgress />
          ) : activityIndicator === 'success' ? (
            <CheckCircleIcon style={{ color: '#28a745' }} fontSize={'large'} />
          ) : (
            <HighlightOffIcon style={{ color: '#dc3545' }} fontSize={'large'} />
          )}
          <Typography
            style={{ textAlign: 'center', marginTop: 10 }}
            variant='h6'
          >
            {`Ukupno ste poslali ${emailCounter}/${csvFile?.emails.length}`}
          </Typography>
          {activityIndicator === 'success' && (
            <Typography
              style={{ textAlign: 'center', marginTop: 10, color: '#28a745' }}
              variant='h6'
            >
              {`Uspješno ste poslali ${csvFile?.emails.length} email-ova.`}
            </Typography>
          )}

          {activityIndicator === 'error' && (
            <Typography
              style={{ textAlign: 'center', marginTop: 10, color: '#dc3545' }}
              variant='h6'
            >
              {`Greška pri slanju email-ova.Provjerite da li ste premašili limit na SendGrid servisu za slanje email-ova.`}
            </Typography>
          )}
        </div>
      </ModalDialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.login,
    loginError: state.login.error,
    openModal: state.sendEmail.openModal,
    activityIndicator: state.sendEmail.activityIndicator,
    emailCounter: state.sendEmail.emailCounter,
  };
};

const mapDispatchToProps = { sendEmailAction, openModalAction };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
