import React from 'react';
import { connect } from 'react-redux';

/* Material UI */
import { Grid, AppBar } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemText from '@material-ui/core/ListItemText';

/* Custom imports */
import { useStyles } from './styles';
import { mobileOpenAction } from '../../../utils/redux/actions/drawer';
import { StyledMenu, StyledMenuItem } from '../Menu';
import { logoutAction } from '../../../utils/redux/actions/login';

function AppBarCustom({ mobileOpenAction, logoutAction }) {
  const classes = useStyles(); // style classes

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.root}>
        <Grid container className={classes.contentHeader}>
          <Typography variant='h6' noWrap>
            Pegasus - Dobrodošli
          </Typography>
          <AccountCircleIcon
            fontSize='large'
            className={classes.accountCircleIcon}
            onClick={(event) => handleClick(event)}
          />
          <StyledMenu
            id='customized-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={() => logoutAction()}>
              <ListItemIcon>
                <ExitToAppIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Odjava' />
            </StyledMenuItem>
          </StyledMenu>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const mapDispatchToProps = { mobileOpenAction, logoutAction };

export default connect(null, mapDispatchToProps)(AppBarCustom);
